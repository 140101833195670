export const config = {
	//isDev: process.env.NODE_ENV !== "production",
	 isDev: true,
	PUBLIC_URL:"/",
	apiUrl:
		process.env.NODE_ENV === "production"
			? "https://apidev.primandapay.fi/public/api"
			//"https://api.primandapay.fi/public/api"
			//https://jukkakokko.com/palkka/public/api
			 //:"https://api.primandapay.fi/public/api"
			: //"http://localhost:8000/api"
			"https://apidev.primandapay.fi/public/api"

	
};
